<template>
  <div class="bg">
    <div class="login">
      <el-form :model="form">
        <div style="text-align: center;padding-bottom: 20px;">Saas管理系统</div>
        <el-form-item>
          <el-input v-model="form.username" placeholder="请输入登录用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.password" type="password" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <div>
          <el-button type="primary" style="width:100%" @click="submitLogin()">登录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/user'
export default {
  data () {
    return {
      form: {
        "username": "",
        "password": ""
      }
    }
  },
  methods: {
    submitLogin () {
      login(this.form).then(res=>{
        const {code, data} = res
        if (code === 10000) {
          localStorage.setItem('token', data.token)
          localStorage.setItem('username', data.username)
          localStorage.setItem('realname', data.realname)
          localStorage.setItem('userid', data.userid)
          this.$router.push('/system')
        } else {
          this.$message.warning(data.msg)
        }
      })
    }
  },
}
</script>

<style>
.bg{
  background: url(../assets/bg.jpg) no-repeat;
  height: 100vh;
  background-size: 100% 100%;
}
.login{
  width: 360px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  box-shadow: 3px 3px 8px 4px #949494;
  border-radius: 10px;
  background: #fff;
}
</style>