import myAxios from "./axios_news";

export const login = params => {
  return myAxios.post("/user/login", params);
};

export const addUser = params => {
  return myAxios.post("/user/addUser", params);
};
export const deleteUser = params => {
  return myAxios.post("/user/deleteUser", params);
};

export const getUserList = params => {
  return myAxios.get("/user/getUserList", {params});
};